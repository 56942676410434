@import '../../styles/utils/__utils';

.container{
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:$spacing-x-small;

    padding: $spacing-large;
    max-width: 80%;

    border-radius: 20px;

    background-color: $color-ice;
}