@import '../../styles/utils/__utils';

.heading {
  margin: 0;

  font-family: 'ZCOOL KuaiLe', cursive;
  font-size: $font-size-x-large;
  font-weight: 400;
  font-weight: bold;

  line-height: 1.6em;
}
