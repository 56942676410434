@import '../../styles/utils/__utils';

.loading {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: $spacing-small;
  width: 100%;
  height: 100%;

  z-index: 100;

  font-size: $font-size-medium;

  color: $color-white;

  background-color: $color-black;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  .icon{
    margin-bottom: $spacing-small;
  }
  .message{
    margin-bottom: $spacing-x-small;

    font-weight: bold;
  }
  .loadingFlair{
    font-size: $font-size-small;
  }

}
