@import '../../styles/utils/__utils';



.intro{
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    background-color: $color-ice;
    .tutorial{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .heading{
            margin-bottom: $spacing-medium;

            font-size: $font-size-xx-large;
        }
        .blocks{
            position: relative;

            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: $spacing-small;

            margin-bottom: $spacing-large;
            width: 80%;
            max-width: 1400px;
            .step{
                position: relative;

                display: grid;
                align-items: flex-end;
                grid-template-rows: 1fr 2fr;

                padding: $spacing-small;
                padding-bottom: 0;
                width: 25%;

                border-radius: 20px;

                background-color: $color-dark;

                .text{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    height: 100%;

                    font-size: $font-size-basis;

                    text-align: center;

                }
                .illustration{
                    display: flex;
                    justify-content: center;

                    max-width: 100%;
                    .fullImage{
                        width: 100%;
                    }
                    .smallImage{
                        margin-bottom: $spacing-medium;
                        max-width: 100%;

                        &.anim{
                            margin-top: $spacing-small;

                            transform-origin: center;

                            animation-iteration-count: infinite;

                            animation-fill-mode: forwards;
                        }
                        &.holsterAnim{
                            animation-name: holster;
                            animation-duration: 4s;
                            @keyframes holster {
                                0%{
                                    transform: rotate(-10deg);
                                }
                                50%{
                                    transform: rotate(10deg);
                                }

                                100%{
                                    transform: rotate(-10deg);
                                }
                            }
                        }
                        &.shootAnim{
                            animation-name: shoot;
                            animation-duration: 4s;
                            @keyframes shoot {
                                0%{
                                    transform: rotate(0deg);
                                }
                                25%, 75%{
                                    transform: rotate(-90deg);
                                }

                                100%{
                                    transform: rotate(0deg);
                                }
                            }
                        }
                    }
                    .qr{
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        margin-bottom: $spacing-large;
                        padding: $spacing-small;

                        border-radius: 25px;

                        background-color: $color-white;
                    }
                }
            }
        }
        .buttons{
            display: flex;
            flex-direction: row;
            gap: $spacing-small;
        }
    }
}