@import '../../styles/utils/__utils';

.logo{
    margin: $spacing-small;
    width: calc(100% - #{$spacing-small * 2});
    max-width: 600px;

    svg, img{
        width: 100%;
        height: auto;
    }
}