@import '../../styles/utils/__utils';


.playerList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: $spacing-medium;

    margin-bottom: $spacing-small;
    padding: 0 $spacing-medium;
    max-height: 40vh;

    overflow-y: auto;

    text-align: center;

    grid-gap: 8px;
    .player{
        padding: $spacing-x-small $spacing-small;

        font-size: $font-size-basis;
        font-weight: bold;

        color: $color-black;

        border-radius: 25px;

        background: $color-gray;
    }
    .noPlayers{
        font-size: $font-size-basis;

        opacity: 0.7;
    }
}
