@import '../../styles/utils/__utils';

.header{
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;

    padding: $spacing-small;
    width: 100%;

    .leave{
        text-decoration: underline;
    }

}