@import '../../styles/utils/__utils';

.users{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: $spacing-small;
    width: 100%;


    .heading{
        display: none;

        font-size: $font-size-small;
        font-weight: normal;
    }
    .userList{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: $spacing-xx-small;

        max-height: 15vh;

        overflow-y: auto;

        .user{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $spacing-small;

            padding: $spacing-x-small $spacing-small;

            font-weight: normal;

            color: $color-white;
            line-height: 1em;

            border-radius: 100px;

            background-color: $color-blue;
            .permission{
                display: flex;
                justify-content: center;
                align-items: center;

                padding: 2px;
                width: 20px;
                height: 20px;

                color: $color-white;

                border-radius: 100%;

                background-color: $color-dark-blue;
                &:not(.allowed){
                    background-color: $color-red;
                }
            }
        }
    }
}