@import '../../styles/utils/__utils';

.wrapper {
  &.stretch {
    width: 100%;
    .input {
      width: 100%;
    }
  }
  &.hidden{
    display: none;
  }
  .title {
    display: block;

    margin-bottom: $spacing-xx-small;

    font-weight: bold;
  }
  .description {
    margin: 0;
    margin-bottom: $spacing-x-small;

    line-height: 1em;
  }
  .container{      position: relative;

    .input {
      padding: $spacing-x-small $spacing-medium;

      font-size: $font-size-small;

      color: $color-black;

      border: none;
      border-radius: $border-radius;

      outline: none;

      background-color: $color-gray;


      &.centered {
        text-align: center;
      }
      &.uppercase{
        text-transform: uppercase;

      }
      &::placeholder {
        text-transform: none !important;
      }
      &:disabled {
        cursor: not-allowed;

        opacity: 0.8;
      }

    }
    .icon{
      position: absolute;
      top: 0;
      right: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      margin: 0 $spacing-x-small;
      height: 100%;

      pointer-events: none;

      font-size: $font-size-medium;

      color: $color-orange;

      opacity: 0.8;
    }
  }
}
