@import '../../styles/utils/__utils';

.main {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;

  color: $color-white;

  background-color: $color-black;
  &.center{
    .content{
      flex-direction: column;
      justify-content: center;
      align-items: center;  
    }
  }
  .background{
    position: absolute;
    top: 0;
    left: 0;

    width: 110%;
    height: 100%;

    background-color: $color-black;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: center center;
    background-position: center center;
    background-size: cover;
    background-size: cover;

    &.animate{
      animation-name: idle;
      animation-duration: 60s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      animation-fill-mode: both;
    }
    @keyframes idle {
        0%{
            transform: scale(1.1) translateX(0%);
        }
        50%{
          transform: scale(1) translateX(-8%);

        }
        100%{
          transform: scale(1.1) translateX(0%);
        }
    }

    .overlay{
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: black;
    }
  }
  .content{
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    width: 100%;
    height: 100%;

    overflow: hidden;

    z-index: 10;
  }
}
