@import '../../styles/utils/__utils';

.debug{
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: space-between;

    padding: $spacing-small;
    width: 100%;


    .data{
        opacity: 0.2;
    }
}