@import '../../styles/utils/__utils';

.error {
  margin-bottom: $spacing-x-small;
  padding: $spacing-x-small $spacing-small;

  font-size: $font-size-small;

  color: $color-white;

  border-radius: 100px;

  background-color: $color-red;
}
