@import '../../styles/utils/__utils';

.credits{
    position: absolute;
    right: 0;
    bottom: 0;

    padding: $spacing-xx-small;

    font-size: 10px;

    color: $color-white;

    opacity: 0.1;
}