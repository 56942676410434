@import '../../styles/utils/__utils';

.footer{
    display: flex;
    flex-direction: column;

    width: 100%;

    z-index: 10;

    background-color: $color-glass;

    backdrop-filter: blur(5px);
}