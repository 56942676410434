@import '../../styles/utils/__utils';

.user{
    display: flex;
    flex-direction: column;
    gap: $spacing-x-small;

    margin-bottom: $spacing-small;

    font-size: $font-size-medium;

    text-align: center;
    .value{
        font-weight: bold;
    }
}
