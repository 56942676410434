@import '../../styles/utils/__utils';

.stage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: $spacing-small;
  padding-bottom: $spacing-large;
  width: 100%;
  height: 100%;

  color: $color-white;
}
