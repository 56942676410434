@import '../../styles/utils/__utils';

.header{
    display: flex;
    flex-direction: column;

    width: 100%;

    z-index: 10;
    .menu{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        padding: $spacing-x-small $spacing-small;

        background-color: $color-ice;

        backdrop-filter: blur(5px);
        .logo{
            cursor: pointer;
        }
        .buttons{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: $spacing-small;

            .audio{
                display: flex;
                align-items: center;

                margin-top: $spacing-small;
                margin-bottom: $spacing-small;

                cursor: pointer;
                .icon{
                    margin-left: $spacing-x-small;
                    width: 30px;
                    height: 30px;

                    font-size: $font-size-large;
                }
            }
            .leave{
                text-decoration: underline;
            }
        }
        .room{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: $spacing-x-small;

            .domain, .code{
                padding: $spacing-x-small  $spacing-small;

                font-weight: bold;

                color: $color-black;

                border-radius: 100px;

                background-color: $color-white;
            }
        }
    }
}