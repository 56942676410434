@import '../../styles/utils/__utils';


.list{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-x-small;

    margin-top: $spacing-x-small;
    margin-bottom: $spacing-small;
    padding: 0 $spacing-x-large;
    max-height: 40vh;

    overflow-y: auto;

    text-align: center;

}