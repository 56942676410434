#test {
  position: absolute;
  top: 50%;
  left: -100vw;

  width: 300vw;
  height: 300vw;

  background: white;

  transform-origin: top center;
}
