@import '../../styles/utils/__utils';

.player{
    position: relative;

    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-small;

    padding: $spacing-x-small;

    font-size: $font-size-medium;
    .background{
        position: absolute;
        top: 0;
        left: 0%;

        width: 100%;
        height: 100%;

        opacity: 0.9;
        svg{
            width: 100%;
            height: 100%;
        }
    }

    &:first-child{
        padding: $spacing-small;

        font-size: $font-size-large;
        font-weight: bold;

        border-radius: 100px;
        .background{
            opacity: 1;
        }
    }
    .cell{
        position: relative;
        &:first-child{
            text-align: center;
        }
        &:last-child{
            display: flex;
            align-items: center;
            gap: $spacing-x-small;

            text-align: left;
        }
        .time{
            font-weight: bold;
        }
        .penalty{
            padding: $spacing-xx-small $spacing-x-small;

            border-radius: 100px;

            background-color: $color-red;
        }
    }
}
