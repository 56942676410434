@import '../../styles/utils/__utils';

.play {
  cursor: none;
  .stage {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    overflow: hidden;

    z-index: 100;

    font-size: $font-size-x-large;
    font-weight: bold;

    opacity: 1;

    .background {
      position: absolute;
      top: 0;
      left: 0;

      width: 110%;
      height: 100%;

      background-color: $color-black;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      animation-name: staredown;
      animation-duration: 16s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      animation-fill-mode: forwards;
      @keyframes staredown {
        0% {
          transform: scale(1.1) translateX(-10%);
        }
        100% {
          transform: scale(1) translateX(0%);
        }
      }
    }
    &.shootout {
      .background {
        width: 100%;

        background-color: $color-red;

        animation-name: shootout;
        animation-duration: 3.5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;

        animation-fill-mode: forwards;
        @keyframes shootout {
          0% {
            transform: scale(1.6);
          }
          10% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
    .instruction {
      z-index: 10;

      font-size: calc(2 * #{$font-size-xx-large});

      line-height: 1em;
    }
  }
}
