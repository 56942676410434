@import '../../styles/utils/__utils';

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-small;

  margin-top: $spacing-medium;
}
