@import '../../styles/utils/__utils';

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: $spacing-x-small $spacing-medium;

  cursor: pointer;

  font-family: 'ZCOOL KuaiLe', cursive;
  font-size: $font-size-medium;
  font-weight: bold;

  color: $color-brown;
  text-decoration: none;

  border: none;
  border-radius: $border-radius;

  background-color: $color-green;
  &.secondary {
    color: $color-white;

    background-color: $color-black;
  }
  &.small {
    font-size: $font-size-x-small;
  }
  &:disabled {
    cursor: not-allowed;

    color: $color-dark-gray;

    background-color: $color-gray;
  }
  .icon {
    margin-top: $spacing-xx-small;
    margin-right: $spacing-x-small;

    font-size: $font-size-medium;

    line-height: 1em;
  }
}
